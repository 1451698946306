@import 'assets/styles/core.scss';

.EntityHistoryList {
  .history {
    padding-bottom: $spacer;
  }

  .history:not(:first-child) {
    padding-top: $spacer;
  }

  .history-action {
    display: flex;

    .circle {
      &:first-child {
        z-index: 1;
      }
    }
  }
}
